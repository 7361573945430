import React from 'react'
import clsx from 'clsx'
import * as styles from './Bracket.module.scss'

export const Bracket = ({ left = true, right = false, className = [] }) => {

  const _className = clsx(
    styles.bracket,
    className,
  )

  if (right) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 207" className={_className}>
        <path d="M1.661 206.498c-1.246 0-1.661-.82-1.661-2.051v-14.356c0-1.641.415-2.462 1.661-2.462h6.644c3.322 0 6.229-1.23 7.89-4.102a19.987 19.987 0 0 0 2.491-11.895v-41.84c0-5.743.416-11.485 2.492-16.818 1.246-4.102 4.152-7.383 7.474-9.434.416-.41.416-.821 0-1.231-3.737-2.051-6.228-5.332-7.474-9.434-1.661-5.333-2.492-11.076-2.492-16.818v-41.02c0-10.665-3.322-15.587-10.38-15.587H1.66C.415 19.45 0 18.63 0 16.99V2.631C0 .992.415.582 1.661.582h13.703c6.23-.41 12.043 2.05 15.78 6.562 4.153 5.743 5.814 13.127 5.398 20.1v46.762c-.415 4.922.416 9.844 2.492 14.357 1.66 2.871 4.568 5.332 7.89 5.742 1.245 0 2.076.82 2.076 2.462v12.715c0 1.641-.415 2.462-2.076 2.462-3.322.41-6.23 2.461-7.89 5.742-2.076 4.512-2.907 9.435-2.492 14.357v47.993c.416 6.973-1.245 13.946-5.398 19.689-4.152 4.512-9.966 7.383-15.78 6.973H1.661Z" />
      </svg>
    )
  }

  if (left) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 207" className={_className}>
        <path d="M17.262 199.379c-3.743-5.743-5.822-12.715-4.99-19.688v-47.988c.415-4.922-.417-9.843-2.496-14.355-1.663-3.281-4.575-5.332-7.902-5.742-1.247 0-1.663-.82-1.663-2.461V96.43c0-1.64.416-2.46 1.663-2.46 3.327-.41 6.654-2.872 7.902-6.153 2.08-4.512 2.911-9.434 2.495-14.355V26.704a34.553 34.553 0 0 1 4.99-20.097C21.006 2.095 27.244-.366 33.066.044h13.724c1.247 0 1.663.82 1.663 2.051V16.45c0 1.641-.416 2.461-1.663 2.461H40.55c-7.07 0-10.396 5.332-10.396 15.586v41.015c.416 5.742-.416 11.485-2.495 16.817-1.248 3.691-4.16 7.382-7.486 9.433-.416.41-.416.82 0 1.231 3.743 2.05 6.238 5.332 7.486 9.433 1.663 5.332 2.495 11.074 2.495 16.816v42.246c0 10.664 3.327 15.996 10.396 15.996h6.239c1.247 0 1.663.82 1.663 2.461V204.3c0 1.641-.416 2.051-1.663 2.051H33.065c-5.822 0-12.06-2.461-15.803-6.972Z" />
      </svg>
    )
  }
}
