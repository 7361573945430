import React from 'react'
import DefaultTemplate
  from '../components/common/Templates/DefaultTemplate/DefaultTemplate'
import Hero from '../components/features/Hero/Hero'
import Vision from '../components/features/Vision/Vision'
import Offer from '../components/features/Offer/Offer'
import { Works } from '../components/features/Works/Works'
import { graphql } from 'gatsby'

const IndexPage = () => {
  return (
    <DefaultTemplate>
      <Hero />
      <Vision />
      <Offer />
      <Works />
    </DefaultTemplate>
  )
}

export default IndexPage

export const query = graphql`
    {
        site {
            siteMetadata {
                title
                description
                keywords
            }
        }
    }
`

export const Head = ({ data }) => (
  <>
    <title>{data.site.siteMetadata.title}</title>
    <meta name="description" content={data.site.siteMetadata.description} />
    <meta name="keywords" content={data.site.siteMetadata.keywords} />
  </>
)
