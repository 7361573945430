import { useRef, useState } from 'react'

/**
 * @param initialState
 * @returns {[React.MutableRefObject<undefined>,number,((value: (((prevState:
 *   number) => number) | number)) => void)]}
 */
export const useContainerWidth = (initialState = 0) => {
  const containerRef = useRef()
  const [width, setWidth] = useState(initialState)

  return [containerRef, width, setWidth]
}
