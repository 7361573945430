import React from 'react'
import * as styles from './Features.module.scss'
import Feature from './Feature'
import {
  Cpu,
  GitMerge,
  MousePointer,
  Settings,
  Star,
  Tool,
} from 'react-feather'

/**
 * @todo: query this from a central place using GraphQL
 */
const offers = [
  {
    label: 'World Class Apps',
    icon: <Star />,
  }, {
    label: 'Pixel-Perfect Code',
    icon: <MousePointer />,
  }, {
    label: 'Software Architecture',
    icon: <Tool />,
  }, {
    label: 'Backend Development',
    icon: <Settings />,
  }, {
    label: 'Frontend Development',
    icon: <GitMerge />,
  }, {
    label: 'Anything IT related',
    icon: <Cpu />,
  },
]

const Features = () => {
  const leftBatch = offers.slice(0, Math.ceil(offers.length / 2))
  const rightBatch = offers.slice(Math.ceil(offers.length / 2))

  return (
    <div className={styles.features}>
      <div className="left">
        {leftBatch.map(({ icon, label }) => (
          <Feature icon={icon} label={label} key={label} />
        ))}
      </div>
      <div className="right">
        {rightBatch.map(({ icon, label }) => (
          <Feature icon={icon} label={label} key={label} />
        ))}
      </div>
    </div>
  )
}

export default Features
