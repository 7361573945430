import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi there! I'm Alex, software engineering enthusiast and lover of clean and high-quality code. I am the right person to
call when you need someone to think about all the magic that is going on behind the scenes of your application.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      