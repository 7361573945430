import React from 'react'
import * as styles from './PageHeading.module.scss'

const PageHeading = ({ children }) => (
  <h2 className={styles.heading}>
    {children}
  </h2>
)

export default PageHeading
