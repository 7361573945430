import React from 'react'
import * as styles from './PageSubHeading.module.scss'

const PageHeading = ({ children }) => (
  <div className={styles.subheading}>
    {children}
  </div>
)

export default PageHeading
