import React, { useLayoutEffect, useRef, useState } from 'react'
import * as styles from './Hero.module.scss'
import IntroText from '../../content/Hero/Intro.mdx'
import LinkButton from '../../common/Button/LinkButton'
import Lines from '../../common/Backgrounds/Lines'
import PageHeading from '../../common/Typography/PageHeading'
import PageSubHeading from '../../common/Typography/PageSubHeading'
import Container from '../../common/Container/Container'
import Grid from '../../common/Grid/Grid'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { useContainerWidth } from '../../../hooks/useContainerWidth'
import { StaticImage } from 'gatsby-plugin-image'

const Hero = () => {
  const textRef = useRef()
  const [bgY, setBgY] = useState(0)
  const [containerRef, width, setWidth] = useContainerWidth(0)
  const windowSize = useWindowSize()

  useLayoutEffect(() => {
    if (textRef.current) {
      const bodyRect = document.body.getBoundingClientRect()
      const text = textRef.current.getBoundingClientRect()
      const offset = (text.top - bodyRect.top)
      setBgY(offset + text.height)
    }

    if (containerRef.current) {
      const bodyRect = document.body.getBoundingClientRect()
      setWidth(bodyRect.width / 2 + 150)
    }
  }, [textRef, containerRef, windowSize, setWidth])

  return (
    <Container
      bg={<Lines top={bgY} width={width} className={styles.lines} />}
      _ref={containerRef}
      className={styles.container}
    >
      <Grid>
        <div className={styles.text}>
          <PageHeading>
            Hi, I'm Alex,<br />
            Full-Stack Developer
          </PageHeading>
          <PageSubHeading>
            Wizard for IT things
          </PageSubHeading>
          <div className={styles.intro} ref={textRef}>
            <IntroText />
          </div>

          <div className={styles.cta}>
            <LinkButton
              variant="primary"
              target="_blank"
              // todo: lazy-load this email so bots don't find it
              email="alex@eggsquare.net"
            >Contact me</LinkButton>
          </div>

        </div>

        <div className={styles.image}>
          {/* fixme: image does not load on phones for some reason */}
          <StaticImage
            src={'../../../images/content/me.jpg'}
            alt="Portrait of Alex"
            className="img-responsive-x"
            width={500}
          />
        </div>
      </Grid>
    </Container>
  )
}

export default Hero
