import React from 'react'
import * as styles from './Project.module.scss'
import { getImage } from 'gatsby-plugin-image'
import { Bracket } from '../Bracket/Bracket'
import Grid from '../Grid/Grid'
import clsx from 'clsx'
// import { Link } from 'gatsby'

/**
 * todo: add proper mobile captions as small overlays
 */
export const Project = ({ node, big = false }) => {

  const { thumbnail } = node.frontmatter
  const image = getImage(thumbnail)

  const cssStyles = {
    backgroundImage: `url(${image.images.fallback.src})`,
  }

  const className = clsx([
    styles.work,
    big && styles.big,
  ])

  return (
    <div className={className} style={cssStyles}>
      <div className={styles.content}>
        {/*<Link to={`/projects/${node.slug}`} className={styles.link}>*/}
        <Grid className={styles.overlay}>
          <Bracket left className={styles.bracket} />

          <div className={styles.title}>{node.frontmatter.title}</div>

          <Bracket right className={styles.bracket} />
        </Grid>
        {/*</Link>*/}
      </div>
    </div>
  )

}
