import React from 'react'
import * as styles from './Vision.module.scss'
import Description from '../../content/Vision/Description.mdx'
import SectionHeading from '../../common/Typography/SectionHeading'
import Container from '../../common/Container/Container'
import Grid from '../../common/Grid/Grid'

const Vision = () => {
  return (
    <Container inverted mt mb>
      <Grid className={styles.grid}>
        <SectionHeading>What I believe in</SectionHeading>
        <div className={styles.vision}>My mission is to create some magic
        </div>
        <div className={styles.description}>
          <Description />
        </div>
      </Grid>
    </Container>
  )
}

export default Vision
