import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Project } from './Project'
import Grid from '../Grid/Grid'

/**
 * todo: on phones implement a slider for all the projects
 */
export const Projects = () => {

  const data = useStaticQuery(graphql`
      query {
          allMdx(
              filter: {fileAbsolutePath: {regex: "/.*/projects/.*/"}}
              sort: {fields: frontmatter___date, order: DESC}
          ) {
              nodes {
                  frontmatter {
                      title
                      thumbnail {
                          childImageSharp {
                              gatsbyImageData
                          }
                      }
                      thumbnail_alt
                  }
                  id
                  slug
              }
          }
      }
  `)

  const projects = data.allMdx.nodes
  const firstOneBig = projects.length % 2 === 1

  return (
    <Grid>
      {projects.map((node, index) => (
        <Project node={node} key={node.id} big={firstOneBig && index === 0} />
      ))}
    </Grid>
  )
}

export default Projects
