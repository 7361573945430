import React from 'react'
import * as styles from './SectionHeading.module.scss'
import clsx from 'clsx'

const SectionHeading = ({ children, center = false, className = [] }) => {

  const _className = clsx([
    styles.heading,
    center && styles.alignCenter,
    ...className,
  ])

  return (
    <div className={_className}>{children}</div>
  )
}

export default SectionHeading
