import { useLayoutEffect, useState } from 'react'

/**
 * taken from https://www.delftstack.com/howto/react/react-window-resize/
 */
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState([0, 0])

  useLayoutEffect(() => {
    const updateWindowSize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', updateWindowSize)

    updateWindowSize()

    return () => window.removeEventListener('resize', updateWindowSize)
  }, [])

  return windowSize
}
