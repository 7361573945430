import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`While other agencies focus on building a product as fast as possible I have the future in mind. My approach targets
clean code, maintainability, and good documentation - factors that save money in the future. I believe quality work pays
off on the long run.`}</p>
    <br />
Also I do not compromise when creating the magic your application deserves 🪄

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      