import React, { useLayoutEffect } from 'react'
import * as styles from './Offer.module.scss'
import SectionHeading from '../../common/Typography/SectionHeading'
import Features from '../../common/Features/Features'
import Lines from '../../common/Backgrounds/Lines'
import Container from '../../common/Container/Container'
import Grid from '../../common/Grid/Grid'
import { useContainerWidth } from '../../../hooks/useContainerWidth'
import { useWindowSize } from '../../../hooks/useWindowSize'

const Offer = () => {
  const [containerRef, width, setWidth] = useContainerWidth(0)
  const windowSize = useWindowSize()

  useLayoutEffect(() => {
    if (containerRef.current) {
      const bodyRect = document.body.getBoundingClientRect()
      setWidth(bodyRect.width / 2)
    }
  }, [containerRef, windowSize, setWidth])

  return (
    <>
      <Container
        bg={
          <Lines bottom={14} height={28} width={width} className={styles.lines} />}
        _ref={containerRef}
        className={styles.container}
      >
        <Grid className={styles.grid}>
          <SectionHeading>What I offer</SectionHeading>
          <div className={styles.heading}>The wizard you call when you're stuck.
          </div>

          <div className={styles.offers}>
            <Features />
          </div>
        </Grid>
      </Container>
    </>
  )
}

export default Offer
