import React from 'react'
import * as styles from './Lines.module.scss'
import clsx from 'clsx'

const Lines = ({
  top = undefined,
  right = 0,
  bottom = undefined,
  left = undefined,
  height = 76,
  width = 857,
  color = 'currentColor',
  zIndex = -1,
  className,
}) => {

  const viewBox = `0 0 ${width} ${height}`
  const ratioHeight = 27 / 76
  const line2Offset = 142
  const ratioPosYLine2 = 49 / 76

  const cssStyles = {
    top,
    right,
    bottom,
    left,
    zIndex,
    width,
  }

  const _className = clsx(
    className,
    styles.container,
  )

  return (
    <div className={_className} style={cssStyles}>
      <svg width="100%" height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width={width} height={height * ratioHeight} fill={color} />
        <rect
          x={line2Offset}
          y={height * ratioPosYLine2}
          width={width - line2Offset}
          height={height * ratioHeight}
          fill={color}
        />
      </svg>
    </div>
  )
}

export default Lines
