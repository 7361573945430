import React from 'react'
import * as styles from './Works.module.scss'
import Projects from '../../common/Projects/Projects'
import Container from '../../common/Container/Container'

export const Works = () => {
  return (
    <>
      <Container id="works" className={styles.container}>
        <div className={styles.hero}>
          <div className={styles.inner}>
            <div className={styles.sectionHeading}>What I do</div>
            <div className={styles.heading}>Works</div>
            <div className={styles.subheading}>
              Learn more about the projects that I have already built and
              deployed successfully or that I am currently building.
            </div>
          </div>
        </div>
      </Container>

      <Container mt={false}>
        <Projects />
      </Container>
    </>
  )
}

export default Works
