import React from 'react'
import * as styles from './Feature.module.scss'

const Feature = ({ icon, label }) => {
  return (
    <div className={styles.feature}>
      <div className={styles.icon}>
        {icon}
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  )
}

export default Feature
